









































































































































































































































































































import { Observer } from 'mobx-vue'
import { Component, Inject, Prop, Ref, Vue } from 'vue-property-decorator'
import { IdoApplyViewModel } from '../viewmodel/ido-apply-viewmodel'

@Observer
@Component({
  components: {},
})
export default class TicketDeposit extends Vue {
  @Inject() vm!: IdoApplyViewModel
}
