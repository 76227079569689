var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-width",class:_vm.$vuetify.breakpoint.smAndDown ? 'mt-6' : ''},[_c('v-row',{staticClass:"ma-0 mb-6 pt-8 pb-10 algo-grey algo-grey darken-1 border-radius-10 text-center"},[_c('v-col',{staticClass:"pa-0",class:{
        'whitelist-border-right': _vm.$vuetify.breakpoint.lgAndUp,
      },attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex flex-column align-center fill-height fill-width pb-0",class:{
          'px-4': _vm.$vuetify.breakpoint.smAndDown,
          'px-9': !_vm.$vuetify.breakpoint.smAndDown,
        }},[_c('div',{staticClass:"whitelist-title-project primary--text"},[_vm._v("Staking pool")]),_c('div',{staticClass:"algo-grey algo-grey darken-2 px-5 py-4 fill-width border-radius-5 mt-6 text-start deposit-frame-width"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"staking-pool-title"},[_vm._v("My Staked LCH")]),_c('div',{staticClass:"staking-value-primary",class:{
                'ml-3': _vm.$vuetify.breakpoint.xl,
                'ml-2': !_vm.$vuetify.breakpoint.xl,
              }},[_vm._v(" "+_vm._s(_vm.vm.tierAmount)+" LCH ")])]),_c('div',{staticClass:"d-flex mt-2"},[_c('div',{staticClass:"staking-pool-title"},[_vm._v("My Tier")]),_c('div',{staticClass:"ml-2 staking-value-primary",class:{
                'ml-3': _vm.$vuetify.breakpoint.xl,
                'ml-2': !_vm.$vuetify.breakpoint.xl,
              }},[_vm._v(" "+_vm._s(_vm.vm.tierIndex)+" ")])])]),_c('div',{staticClass:"mt-3 staking-pool-title"},[_vm._v("You have")]),(_vm.vm.goldenTicketNumber > 0)?_c('div',{staticClass:"border-radius-5 fill-width whitelist-bold-label mt-3 deposit-frame-width",class:{
            'd-flex justify-space-between align-center': !_vm.$vuetify.breakpoint.smAnDown,
            'd-flex flex-column': _vm.$vuetify.breakpoint.sm,
          }},[_c('div',{staticClass:"algo-grey darken-2 d-flex align-center justify-center ticket-number-stats"},[_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.vm.stakingTicketNumber)+" ")]),_vm._v("   Ticket(s) ")]),_c('v-img',{class:_vm.$vuetify.breakpoint.sm ? 'my-2' : 'mx-2',attrs:{"src":require("@/assets/icons/plus-dark-icon.svg"),"max-height":"14px","contain":""}}),_c('div',{staticClass:"d-flex justify-space-between align-center golden-ticket"},[_c('v-img',{staticClass:"ml-2",attrs:{"src":require("@/assets/icons/golden-star-icon.svg"),"max-width":"13","contain":""}}),_c('span',{staticClass:"mx-1"},[_vm._v(_vm._s(_vm.vm.goldenTicketNumber)+" Golden Ticket"+_vm._s(_vm.vm.goldenTicketNumber > 1 ? 's' : ''))]),_c('v-img',{staticClass:"mr-2",attrs:{"src":require("@/assets/icons/golden-star-icon.svg"),"max-width":"13","contain":""}})],1)],1):_c('div',{staticClass:"border-radius-5 algo-grey darken-2 fill-width whitelist-bold-label mt-3 d-flex justify-center align-center deposit-frame-width deposit-frame-height ticket-number-stats"},[_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.vm.stakingTicketNumber)+" ")]),_vm._v("  Ticket(s) ")])])]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex flex-column align-center fill-height fill-width",class:{
          'px-4': _vm.$vuetify.breakpoint.smAndDown,
          'px-9': !_vm.$vuetify.breakpoint.smAndDown,
        }},[(!_vm.$vuetify.breakpoint.lgAndUp)?_c('div',{staticClass:"fill-width algo-grey mt-8 mb-9",staticStyle:{"height":"1px"}}):_vm._e(),_c('div',{staticClass:"whitelist-title-project primary--text"},[_vm._v("Social pool")]),_c('v-img',{staticClass:"align-self-center mt-4",style:(_vm.$vuetify.breakpoint.xl ? 'max-width:71px' : 'max-width:54px'),attrs:{"src":require("@/assets/icons/social-gleam-icon.svg"),"contain":""}}),_c('v-spacer',{staticClass:"flex-fill"}),_c('div',{staticClass:"mt-3 staking-pool-title"},[_vm._v("You have")]),_c('div',{staticClass:"border-radius-5 algo-grey darken-2 fill-width whitelist-bold-label mt-2 d-flex justify-center align-center deposit-frame-width deposit-frame-height ticket-number-stats"},[_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.vm.socialTicketNumber))]),_vm._v("   Social Winning Ticket(s) ")])],1)])],1),_c('div',{staticClass:"algo-grey algo-grey darken-1 border-radius-10 d-flex flex-column align-center fill-height fill-width deposit-padding frame-box-shadow"},[_c('v-img',{staticClass:"align-self-center",style:(_vm.$vuetify.breakpoint.xl ? 'max-width:75px' : 'max-width:57px'),attrs:{"src":require("@/assets/icons/staking-ticket-icon.svg"),"contain":""}}),_c('div',{staticClass:"mt-4 mb-2 buy-up-text"},[_vm._v("You can buy up to")]),_c('div',{staticClass:"algo-grey darken-2 d-flex align-center fill-width justify-center border-radius-5 usd-allocation staking-value-primary"},[_vm._v(" "+_vm._s(_vm._f("usdCustom")(_vm.vm.usdTicketAllocation,0, 2))+" worth tickets ")]),_c('div',{staticClass:"fill-width algo-grey",class:{
        'mt-9 mb-16': _vm.$vuetify.breakpoint.xl,
        'mt-8 mb-10': !_vm.$vuetify.breakpoint.xl,
      },staticStyle:{"height":"1px"}}),_c('div',{staticClass:"whitelist-title-project primary--text mb-3"},[_vm._v(_vm._s(_vm.vm.poolName)+" Public Sale")]),_c('div',{staticClass:"text-center ticket-cost-text"},[_vm._v("Each ticket costs "+_vm._s(_vm._f("usdCustom")(_vm.vm.pricePerTicket,0, 5)))]),_c('div',{staticClass:"deposit-content-width",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"algo-grey algo-grey darken-2 fill-width border-radius-10 mt-5 d-flex flex-column"},[_c('div',{class:{
            'd-flex flex-column px-4 pt-4 pb-10': _vm.$vuetify.breakpoint.smAndDown,
            'd-flex align-center px-6 pb-7 pt-2': !_vm.$vuetify.breakpoint.smAndDown,
          }},[_c('div',{staticClass:"d-flex align-center justify-start",class:{
              'mt-3': !_vm.$vuetify.breakpoint.smAndDown,
            },staticStyle:{"min-width":"133px"}},[_c('v-img',{staticClass:"mt-2",attrs:{"src":require("@/assets/project-icon.png"),"max-width":"39","contain":""}}),_vm._m(0)],1),_c('div',{staticClass:"flex-fill d-flex flex-column"},[_c('div',{staticClass:"align-self-end deposit-caption-text justify-self-end"},[_vm._v("Balance "+_vm._s(_vm.vm.remainingTickets))]),_c('v-card',{staticClass:"flex-fill border-radius-5",attrs:{"height":"48"}},[_c('v-text-field',{staticClass:"input",attrs:{"hide-details":"","flat":"","solo":"","single-line":"","placeholder":"0"},on:{"input":_vm.vm.depositTicketInputOnChange},model:{value:(_vm.vm.depositTicketInput),callback:function ($$v) {_vm.$set(_vm.vm, "depositTicketInput", $$v)},expression:"vm.depositTicketInput"}},[_c('div',{staticClass:"d-flex align-center",attrs:{"slot":"append"},slot:"append"},[_c('v-btn',{staticClass:"algo-btn btnC border-radius-5 deposit-max-btn",attrs:{"small":""},on:{"click":function($event){return _vm.vm.depositTicketInputOnChange(("" + (_vm.vm.remainingTickets)))}}},[_vm._v("MAX ")])],1)])],1)],1)])]),_c('div',[_c('v-img',{staticClass:"usd-convert-arrow",attrs:{"src":require("@/assets/icons/arrow-down-circle.svg"),"max-height":"39","contain":""}})],1),_c('div',{staticClass:"algo-grey algo-grey darken-2 fill-width border-radius-10 mt-2 d-flex flex-column"},[_c('div',{class:{
            'd-flex flex-column px-4 pt-4 pb-10': _vm.$vuetify.breakpoint.smAndDown,
            'd-flex align-center px-6 pb-7 pt-2 ': !_vm.$vuetify.breakpoint.smAndDown,
          }},[_c('div',{staticClass:"d-flex align-center justify-start",class:{
              'mt-3': !_vm.$vuetify.breakpoint.smAndDown,
            },staticStyle:{"min-width":"133px"}},[_c('v-img',{staticClass:"mt-1",attrs:{"src":require("@/assets/icons/dollar-icon.svg"),"max-width":"39","contain":""}}),_vm._m(1)],1),_c('div',{staticClass:"flex-fill d-flex flex-column"},[_c('div',{staticClass:"align-self-end deposit-caption-text justify-self-end"},[_vm._v(" Balance "+_vm._s(_vm._f("formatNumber")(_vm.vm.tradeTokenBalance,8, 0))+" ")]),_c('v-card',{staticClass:"px-2 flex-fill border-radius-5 transparent-bg common-border-card"},[_c('v-text-field',{staticClass:"input no-padding-text-field transparent-text-field fill-width",attrs:{"hide-details":"","disabled":true,"flat":"","solo":"","single-line":"","placeholder":"0"},model:{value:(_vm.vm.depositAmountInput),callback:function ($$v) {_vm.$set(_vm.vm, "depositAmountInput", $$v)},expression:"vm.depositAmountInput"}})],1)],1)])])]),_c('div',{staticClass:"error--text font-weight-medium text-body-2 mt-4 text-end deposit-content-width",staticStyle:{"height":"30px","width":"100%"}},[_vm._v(" "+_vm._s(_vm.vm.depositError)+" ")]),(_vm.vm.isOpInt)?_c('v-btn',{staticClass:"deposit-content-width algo-btn btnA mt-2 text-none",class:{
        'button-50': !_vm.$vuetify.breakpoint.xl,
        'button-60': _vm.$vuetify.breakpoint.xl,
      },attrs:{"loading":_vm.vm.depositLoading || _vm.vm.fetchingData},on:{"click":function($event){return _vm.vm.depositTicket()}}},[_vm._v(" Deposit ")]):_c('v-btn',{staticClass:"deposit-content-width algo-btn btnA mt-2 text-none",class:{
        'button-50': !_vm.$vuetify.breakpoint.xl,
        'button-60': _vm.$vuetify.breakpoint.xl,
      },attrs:{"loading":_vm.vm.optInLoading || _vm.vm.fetchingData},on:{"click":function($event){return _vm.vm.opInt()}}},[_vm._v(" Opt-in ")]),(_vm.vm.depositedTicketNumber > 0)?_c('div',{staticClass:"algo-grey darken-2 mt-6 fill-width border-radius-5 deposit-content-width deposited-tickets white--text staking-value-primary",class:{
        ' d-flex align-center  justify-center': !_vm.$vuetify.breakpoint.smAndDown,
        ' d-flex flex-column align-center justify-center pa-6  ': _vm.$vuetify.breakpoint.smAndDown,
      },staticStyle:{"border":"1px solid var(--v-primary-base) !important","position":"relative"}},[_c('v-img',{class:{
          'deposited-tickets-icon': !_vm.$vuetify.breakpoint.smAndDown,
        },attrs:{"src":require("@/assets/icons/staking-ticket-icon-small.svg"),"max-width":"50","contain":""}}),_c('div',{class:{
          'mt-2': _vm.$vuetify.breakpoint.smAndDown,
        }},[_vm._v(" You have deposited "),_c('span',{staticClass:"primary--text ml-1"},[_vm._v(_vm._s(_vm.vm.depositedTicketNumber)+" ticket"+_vm._s(_vm.vm.depositedTicketNumber > 1 ? 's' : '')+" ")])])],1):_vm._e()],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-start mx-2"},[_c('div',{staticClass:"deposit-caption-text"},[_vm._v("Receive")]),_c('div',{staticClass:"deposit-title-text"},[_vm._v("Ticket")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-start mx-2"},[_c('div',{staticClass:"deposit-caption-text"},[_vm._v("Deposit with")]),_c('div',{staticClass:"deposit-title-text"},[_vm._v("USDC")])])}]

export { render, staticRenderFns }